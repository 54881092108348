@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/style.css';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.adminbar {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 20px;
}

.marker {
  position: relative;
  z-index: 9990;
}

.marker .title {
  padding: 4px;
  display: inline-block;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  border: 1px solid black;
}

.marker.markerveh {
  z-index: 99;
}

.marker .title.titleveh {
  display: flex;
  border: none;
  flex-direction: column-reverse;
  align-items: center;
}

.marker .title.titleveh img {
  width: 20px;
  height: auto;
}

.marker .title.titleveh .spacer {
  background-color: black;
  border: none;
  width: 2px;
  height: 16px;
}

.marker .title.titleveh .spacer.spacership {
  background-color: rgb(0, 77, 149);
  border: none;
  width: 2px;
  height: 16px;
}

.marker .title.titleveh span {
  background-color: rgb(255, 255, 255);
  border: 1px solid black;
  font-size: 11px;
  padding: 4px;
  font-weight: bold;
}

.marker .title.titleveh.ship span {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(26, 89, 156);
  font-size: 11px;
  padding: 4px;
  font-weight: bold;
  color: rgb(26, 89, 156);
}

.marker .pin {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 6px solid black;
  position: absolute;
  left: calc((100% / 2) - 6px);
  bottom: -6px;
}

.marker .line {
  position: absolute;
  width: 2px;
  height: 20px;
  background-color: black;
  left: 50%;
  bottom: 0;
}

.marker-details {
  display: none;
  transition: all;
}
.highlight .marker-details {
  padding: 4px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
}

.apptable tr {
  border-bottom: 1px solid lightgray;
}

.apptable tr td {
  padding: 2px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-right: 1px solid lightgray;
}

.apptable thead td {
  border-right: 1px solid gray;
}
