/* background */

.app {
  @apply bottom-0 left-0 right-0 top-0 absolute overflow-hidden flex flex-col;
}

.ano-bg {
  @apply bg-primary-400 w-full h-screen xl:py-24 lg:py-12 p-4;
}

/* header */
.header {
  @apply h-[60px] bg-slate-700 w-full flex flex-row items-center justify-between pl-4 pr-4 border-b-[1px] border-b-slate-600;
}

.header .user {
  @apply relative hover:cursor-pointer;
}

.header .user .pop {
  @apply hidden absolute right-0 top-8 bg-slate-900 text-white p-2 rounded text-xs z-[999];
}

.avatar {
  @apply w-8 h-8 rounded-3xl bg-secondary-300 text-xs flex items-center justify-center;
}

.header .create,
.header .recent {
  @apply relative hover:cursor-pointer ml-4;
}

.header .create .btn,
.header .recent .btn {
  @apply rounded-3xl border-white border h-8 w-[82px] flex justify-center text-xs text-white p-1 pr-2 hover:bg-slate-600;
}

.header .create .pop {
  @apply hidden absolute left-[86px] top-0 bg-gray-100 p-4 rounded-lg flex-col items-start z-[999] w-32;
}

.header .recent .pop {
  @apply hidden absolute left-[86px] top-0 bg-gray-100 p-4 rounded-lg flex-col items-start z-[999] w-[700px] text-left;
}

.header .create .pop button,
.header .recent .pop button {
  @apply text-gray-500 hover:text-gray-800 flex flex-row items-center mb-2;
}

.header .create .pop button svg {
  @apply mr-1;
}

.page-container {
  @apply flex flex-1 flex-row;
}

/* main with header and content */

.main {
  @apply flex flex-1 flex-col w-full ease-in-out duration-100;
}

.main.wsidebar {
  @apply lg:max-w-[80vw] duration-300;
}

.main.nosidebar {
  @apply lg:max-w-full duration-150;
}

/* content */

.content {
  @apply w-full min-h-full;
}

/* sidebar */

.sidebar {
  @apply top-0 h-full z-40 flex flex-col ease-in-out duration-300 bg-slate-700 text-white relative;
}

.sidebar .container {
  @apply w-full h-full;
}

.sidebar .nav {
  @apply p-4 pl-4 w-full;
}

.sidebar .nav .icon {
  @apply min-w-[26px] min-h-[26px] mr-2;
}

.sidebar .user {
  @apply w-full flex flex-row items-center h-12 lg:h-20 bg-blue-300 pl-6;
}

.sidebar .user .name {
  @apply text-sm lg:text-xl;
}

.app-table {
  @apply table-auto text-sm w-full;
}

.app-table thead {
  @apply bg-gray-100;
}

.app-table thead th {
  @apply py-2 border-r-2;
}

.app-table thead th:last-child {
  @apply py-2 border-r-0;
}

.box {
  @apply rounded-xl bg-gray-100 flex flex-col lg:flex-row lg:justify-between text-xs lg:text-lg mb-4;
}

.box-label {
  @apply inline-block w-24 lg:w-28 font-bold;
}

.box-action {
  @apply w-full lg:w-40 rounded-br-lg rounded-bl-lg lg:rounded-bl-none lg:rounded-tr-lg py-2 px-2 bg-green-500 text-white;
}

.styled-btn {
  @apply bg-blue-400 text-white font-bold py-2 px-4 mb-2 rounded text-center;
}

.styled-btn.primary {
  @apply bg-blue-500;
}

.styled-btn.danger {
  @apply bg-red-500;
}

.styled-btn.cancel {
  @apply bg-gray-500;
}

.styled-btn.inverse {
  @apply bg-black text-white;
}

.styled-btn.outlined {
  @apply bg-transparent text-blue-500 border-none;
}

.appinput,
.appselect {
  @apply w-full mb-2 h-8 text-xs p-2 rounded-sm border-gray-200 border-[1px];
}

.appform {
  @apply text-xs;
}

.appform label {
  @apply font-bold mb-1;
}

.appform input,
.appform select,
.appform textarea {
  @apply mb-2;
}

.appcheckbox {
  @apply flex flex-row items-center mb-2 !font-normal;
}

.appcheckbox input {
  @apply m-0 mr-2;
}

/*searchbar*/
.searchtitle {
  @apply text-sm mt-2 text-slate-700 font-bold mb-0 pb-0;
}
